import lottie from 'lottie-web';

const isInViewport = function(elem) {
  var distance = elem.getBoundingClientRect();
  return (
    distance.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    distance.bottom > 0 &&
    distance.top > (window.innerHeight / -3)
  );
};

window.isInViewport = isInViewport;

class TrailerEmbed {
  constructor() {
    this.trailerLink = document.getElementById("play-trailer");

    $(this.trailerLink).fancybox({
      media: { youtube: { controls: 0, showinfo: 0, autoplay: 1, rel: 0, hd: 1 } },
      youtube: { autoplay: 1 },
      infobar: false,
      animationEffect: 'zoom-in-out',
      buttons: [ 'fullScreen', 'close' ],
      btnTpl: { fullScreen: '<button data-fancybox-fullscreen class="fancybox-button fancybox-button--fullscreen" title="{{FULL_SCREEN}}"><svg class="icon"><use xlink:href="#icon-fullscreen"></use></svg></button>' }
    });
  }
}

class Clip {
  constructor(element) {
    this.element = element;
    this.playing = false;  
  }

  play() {
    if (this.playing) return;

    let isVideo = this.element.nodeName.toLowerCase() === 'video';
    isVideo ? this.element.play() : this.element.click();
  
    this.playing = true;
  
  }

  pause() {
    if (!this.playing) return;

    let isVideo = this.element.nodeName.toLowerCase() === 'video';
    isVideo ? this.element.pause() : this.element.click();
  
    this.playing = false;
  }

  inViewport() {
    return isInViewport(this.element);
  }
}

class Perf {
  videos = null

  constructor(clips) {
    this.videos = clips;
    this.elements = [...this.videos].map(e => new Clip(e));

    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll = () => {
    const { elements } = this;
    for (let el = 0; el < elements.length; el++) {
      let element = elements[el];
      if (element.inViewport()) {
        element.play();
      } else {
        element.pause();
      }
    }
  }
}

window.onload = () => {
  console.warn('Starting main.js...')
  new TrailerEmbed();

  // Remove `no-js` from body.
  document.body.classList.remove('no-js');

  // Set up Lottie animations.
  let animations = [
    { name: 'glide.json', target: '#glide-anim' },
    { name: 'swim.json', target: '#swim-anim' },
    { name: 'wiggle.json', target: '#wiggle-anim' },
    { name: 'bend.json',   target: '#bend-anim' },
    { name: 'hide.json',   target: '#hide-anim' },
    { name: 'soar.json',   target: '#soar-anim' },
  ];

  animations.forEach(a => {
    lottie.loadAnimation({
      container: document.querySelector(a.target),
      path: `/assets/animations/${a.name}`,
      renderer: 'svg',
      name: a.name.replace('.json', ''),
      loop: true
    });
  });

  // Auto-show the trailer and intro text.
  let trailerImage = document.getElementById('trailer-image');
  let intro = document.getElementById('intro');

  [trailerImage, intro].forEach((el, index) => setTimeout(() => el.classList.remove('hidden'), index * 500));
  
  // Play game videos as they enter the viewport.
  let gameVideos = document.querySelectorAll('[id^="video-"]');
  let p = new Perf(gameVideos);
  p.handleScroll();
};
